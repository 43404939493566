/* eslint-disable camelcase */
/** @prettier */
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  useEffectOnce,
  useInterval,
  useSetState,
  useUpdateEffect,
} from 'react-use';

import type { WBUserDataPayload } from '../../types';

import { BUY_BUTTON_FIRST_CLICK } from '../../../../scripts/afterBlocks/experiments/customAuth';
import { logOut } from '../../../../scripts/blocks/user-account-services/index';
import { Translations } from '../../../../scripts/types';
import { scrollToBlockById } from '../../../../utils/common-helper';
import Button from '../../Button';
import Description from '../../Description';
import Logo from '../../Logo';
import Title from '../../Title';
import Modal from '../Modal';

import './style.pcss';

type UserAccountProps = {
  userData: WBUserDataPayload;
  translations: Translations;
  mountNode: HTMLElement;
  buttonId?: string;
};

const UserAccount = ({
  userData: { partner_data: partnerData },
  translations,
  mountNode,
  buttonId,
}: UserAccountProps) => {
  const [isSpinnerShown, setSpinnerShow] = useState(!!partnerData);
  const [isShow, setShowModal] = useState(true);
  const playerNameRef = useRef<HTMLSpanElement>(null);
  const [fontState, setFontState] = useSetState({
    fontSize: 20,
    time: 0,
    delay: partnerData ? 10 : null,
  });

  useEffectOnce(() => {
    if (!partnerData) {
      setFontState({ delay: null });
    }
  });

  useUpdateEffect(() => {
    setSpinnerShow(false);
  }, [fontState.delay]);

  /**
   * Reduce the font size so that it is completely within the block
   * Added interval to wait font to be rendered
   * */
  useInterval(() => {
    const width = playerNameRef?.current?.offsetWidth;
    if (width && width > 205) {
      setFontState((prev) => ({
        fontSize: prev.fontSize - 1,
      }));
    }
    setFontState((prev) => ({
      time: prev.time + 10,
      delay: prev.time >= 300 ? null : 10,
    }));
  }, fontState.delay);

  const logOutFromSocialNetwork = () => {
    sessionStorage.setItem('logOutFromSocialNetwork', 'true');
    setSpinnerShow(true);
    logOut();
  };

  const close = () => {
    setShowModal(false);
    ReactDOM.unmountComponentAtNode(mountNode);
  };

  const openPayStation = (buttonId) => {
    const interval = setInterval(() => {
      const BB = document.querySelector(
        `#${buttonId} .x-buy-button-widget-simple-payment-button`
      ) as HTMLButtonElement;
      if (!BB) {
        return;
      }
      scrollToBlockById(buttonId, { block: 'center' });
      BB.click();
      localStorage.removeItem(BUY_BUTTON_FIRST_CLICK);
      clearInterval(interval);
      close();
    }, 50);
  };

  const continueClickHandler = () => {
    if (buttonId) {
      openPayStation(buttonId);
    } else {
      close();
    }
  };

  return (
    <Modal isSpinnerShown={isSpinnerShown} onClose={close} isShow={isShow}>
      <Logo />
      <Title text={translations.wb_user_account_modal_title} />
      <Description text={translations.wb_user_account_modal_description} />
      {partnerData && (
        <div className='user-account-modal__user-info-wrapper'>
          <div className='user-account-modal__user-level'>
            <span className='user-account-modal__user-level-text'>
              {partnerData.player_level}
            </span>
          </div>
          <div className='user-account-modal__user-info'>
            <div className='user-account-modal__user-kingdom'>
              {translations.wb_user_account_modal_kingdom} #
              {partnerData.kingdom_name}
            </div>
            <div className='user-account-modal__user-name'>
              <span
                style={{
                  fontSize: `${fontState.fontSize}px`,
                }}
                ref={playerNameRef}
              >
                {partnerData.player_name}
              </span>
            </div>
          </div>
        </div>
      )}
      <Button handleClick={() => continueClickHandler()}>
        {translations.wb_user_account_modal_continue}
      </Button>
      <span
        className='user-account-modal__subtext'
        onClick={() => logOutFromSocialNetwork()}
      >
        {translations.wb_user_account_modal_logout}
      </span>
    </Modal>
  );
};

export default UserAccount;
