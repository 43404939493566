import { Landing } from '@site-builder/common/src/types/model/landing';

import { getFeatures } from '../../../utils/api';
import { FeaturesType } from '../../../utils/types';
import { Action, Dispatch, GetState } from '../../types';
import { RootState } from '../../types/root-state';

export type FeaturesState = {
  loaded: boolean;
  data: FeaturesType[];
};

const initialState: FeaturesState = {
  loaded: false,
  data: [],
};

export const INIT_FEATURES = 'INIT_FEATURES';

export const initFeatures =
  (landing: Landing) => async (dispatch: Dispatch) => {
    const { merchantId } = landing;
    const response = await getFeatures({ merchantId });
    if (response.status !== 200) {
      return false;
    }
    const { data } = response;
    return dispatch({
      type: INIT_FEATURES,
      data,
    });
  };

export const initServerFeatures =
  (data: FeaturesType[]) => async (dispatch: Dispatch) =>
    dispatch({
      type: INIT_FEATURES,
      data,
    });

export const getFeatureByName =
  (name: string) => (dispatch: Dispatch, getState: GetState<RootState>) =>
    getState().features.data.find((feature) => feature.name === name);

export const getEnabledFeatureByName =
  (name: string) => (dispatch: Dispatch, getState: GetState<RootState>) =>
    getState().features.data.find(
      (feature) => feature.name === name && feature.enabled === true
    );

export default function featureReducer(
  state: FeaturesState = initialState,
  action: Action
) {
  switch (action.type) {
    case INIT_FEATURES:
      return { ...state, data: action.data, loaded: true };
    default:
      return state;
  }
}
