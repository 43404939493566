import {
  AbstractLocalizedValue,
  StepOfUserInstruction,
} from '@site-builder/common/src/types/values';
import { FiveSymbolLocale } from '@site-builder/common/src/utils/locale';

import { getInstructionStepText } from '../../../utils/translation';

const getDescriptionText = ({
  languages,
}: {
  languages: FiveSymbolLocale[];
}): AbstractLocalizedValue => {
  const value = { enable: true };

  languages.forEach((language: FiveSymbolLocale) => {
    value[language] = getInstructionStepText(language);
  });

  return value as AbstractLocalizedValue;
};

const imageValue = () => ({
  enable: true,
  type: 'image',
  size: 'contain',
  img: '',
  video: '',
  color: 'rgba(0,0,0,0)',
});

export const createStep = ({ languages }: { languages: FiveSymbolLocale[] }) =>
  ({
    description: getDescriptionText({ languages }),
    image: imageValue(),
  } as StepOfUserInstruction);
