/** @prettier */
const localizations = {
  wb_auth_modal_account_bind: 'Precisa vincular uma conta existente?',
  wb_auth_modal_account_create: 'Precisa criar uma conta GoT:C?',
  wb_auth_modal_apple: 'Iniciar sessão com a Apple',
  wb_auth_modal_conjunction_word: '-ou-',
  wb_auth_modal_description:
    'Esta loja usa as credenciais vinculadas à sua conta Game of Thrones: Conquest para identificá-la',
  wb_auth_modal_facebook: 'Iniciar sessão com o Facebook',
  wb_auth_modal_google: 'Iniciar sessão com o Google',
  wb_auth_modal_link_bind:
    'https://hbogamessupport.wbgames.com/hc/pt/articles/360001088488-FAQ-Salvamento-na-nuvem',
  wb_auth_modal_title: 'Inicie a sessão com sua conta GoT:C vinculada',
  wb_notification_added_to_cart: 'Adicionado ao seu carrinho',
  wb_notification_updated_cart: 'Seu carrinho foi atualizado',
  wb_selector_amount_in_cart: 'No carrinho',
  wb_user_account_modal_continue: 'Continuar',
  wb_user_account_modal_description:
    'Esta loja usa as credenciais vinculadas à sua conta Game of Thrones: Conquest para identificá-la',
  wb_user_account_modal_error:
    'Não encontramos a conta associada a esta credencial. Tente novamente.',
  wb_user_account_modal_footer_privacy: 'Política de Privacidade',
  wb_user_account_modal_footer_terms: 'Termos de Uso',
  wb_user_account_modal_footer_text:
    'Após o login, você será direcionado para um site de compras operado pela Xsolla, a qual é a única responsável pelo conteúdo do website, bem como por todos os aspectos das compras.',
  wb_user_account_modal_kingdom: 'Reino',
  wb_user_account_modal_logout: 'Sair',
  wb_user_account_modal_title: 'VINCULAR ESTA CONTA GOTC',
};

export default localizations;
