import { AuthorizationTypes as AuthorizationType } from '@site-builder/common/src/types/model/landing';

import type { Theme } from '../../../utils/login/types';
import type { Authorization } from '@site-builder/common/src/types/model/landing';
import type { FiveSymbolLocale } from '@site-builder/common/src/utils/locale';

import { isEAMerchant } from '../../../experiments/ea-constants';
import { isCustomAuth } from '../../../experiments/partnersRequests/utils';
import { Environment } from '../../../utils/types';
import { UserAccountEAAuthService } from './partner-services/user-account-ea-auth-service';
import { UserAccountWBAuthService } from './partner-services/user-account-wb-auth-service';
import {
  IUserAccountIdServiceProps,
  UserAccountIdService,
} from './user-account-id-service';
import {
  IUserAccountLoginServiceProps,
  UserAccountLoginService,
} from './user-account-login-service';
import { UserAccountService } from './user-account-service';

type IUserAccountServiceJwt = {
  isPreviewFrameMode: boolean;
  projectId: string;
  locale: FiveSymbolLocale;
  loginId: string;
  loginWidgetGeneration: 0 | 1 | null;
  loginTheme: Theme;
  merchantId: string;
};

export const getLoginService = ({
  merchantId,
  projectId,
  locale,
  loginId,
  loginWidgetGeneration,
  loginTheme,
  auth,
}: IUserAccountServiceJwt & {
  auth: Authorization;
  environment: Environment;
}): UserAccountService => {
  const loginData: IUserAccountLoginServiceProps = {
    merchantId,
    projectId,
    locale,
    loginId,
    loginWidgetGeneration,
    loginTheme,
  };
  const userIdData: IUserAccountIdServiceProps = {
    projectId,
    merchantId,
    auth,
  };
  if (isCustomAuth()) {
    return UserAccountWBAuthService.getInstance(loginData);
  }
  if (isEAMerchant(merchantId)) {
    const userAccountService = UserAccountEAAuthService.getInstance(userIdData);
    if (userAccountService.isEmailAuth) {
      return userAccountService;
    }
  }
  if (auth.type === AuthorizationType.LOGIN && loginId) {
    return UserAccountLoginService.getInstance(loginData);
  }
  if (auth.type === AuthorizationType.USER_ID) {
    return UserAccountIdService.getInstance(userIdData);
  }
  return new UserAccountService({ projectId, merchantId });
};
