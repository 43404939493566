/** @prettier */
const localizations = {
  wb_auth_modal_account_bind: 'Vuoi associare un account esistente?',
  wb_auth_modal_account_create: 'Vuoi creare un account TdS:C?',
  wb_auth_modal_apple: 'Accedi con Apple',
  wb_auth_modal_conjunction_word: '-o-',
  wb_auth_modal_description:
    'Questo negozio usa la tua associazione Il Trono di Spade: La Conquista per trovare il tuo account',
  wb_auth_modal_facebook: 'Accedi con Facebook',
  wb_auth_modal_google: 'Accedi con Google',
  wb_auth_modal_link_bind:
    'https://hbogamessupport.wbgames.com/hc/it/articles/360001088488-Domande-frequenti-sul-salvataggio-nel-Cloud',
  wb_auth_modal_title: "Accedi con l'associazione TdS:C",
  wb_notification_added_to_cart: 'Aggiunto al carrello',
  wb_notification_updated_cart: 'Il tuo carrello è stato aggiornato',
  wb_selector_amount_in_cart: 'Nel carrello',
  wb_user_account_modal_continue: 'Continua',
  wb_user_account_modal_description:
    'Questo negozio usa la tua associazione Il Trono di Spade: La Conquista per trovare il tuo account',
  wb_user_account_modal_error:
    'Non siamo riusciti a trovare un account associato. Riprova.',
  wb_user_account_modal_footer_privacy: 'Informativa sulla privacy',
  wb_user_account_modal_footer_terms: "Condizioni d'uso",
  wb_user_account_modal_footer_text:
    "Dopo l'accesso verrai reindirizzato a un sito per gli acquisti operato da Xsolla, unica azienda responsabile del contenuto del sito e di tutti gli aspetti dell'acquisto.",
  wb_user_account_modal_kingdom: 'Regno',
  wb_user_account_modal_logout: 'Esci',
  wb_user_account_modal_title: 'COLLEGA QUESTO ACCOUNT TDSC',
};

export default localizations;
