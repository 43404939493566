/** @prettier */
const localizations = {
  wb_auth_modal_account_bind: 'Mevcut bir hesabı bağlamanız mı gerekiyor?',
  wb_auth_modal_account_create: 'Bir GoT:C hesabı oluşturmanız mı gerekiyor?',
  wb_auth_modal_apple: 'Apple ile Giriş Yapın',
  wb_auth_modal_conjunction_word: '-veya-',
  wb_auth_modal_description:
    'Bu mağaza, hesabınızı bulmak için Game of Thrones: Conquest hesabı bağlantınızı kullanır',
  wb_auth_modal_facebook: 'Facebook ile Giriş Yapın',
  wb_auth_modal_google: 'Google ile Giriş Yapın',
  wb_auth_modal_link_bind:
    'https://hbogamessupport.wbgames.com/hc/tr/articles/360001088488-Buluta-Kaydetme-SSS',
  wb_auth_modal_title: 'GOT:C Bağlantınız ile Giriş Yapın',
  wb_notification_added_to_cart: 'Sepete eklendi',
  wb_notification_updated_cart: 'Sepetiniz güncellendi',
  wb_selector_amount_in_cart: 'Sepette',
  wb_user_account_modal_continue: 'Devam Et',
  wb_user_account_modal_description:
    'Bu mağaza, hesabınızı bulmak için Game of Thrones: Conquest hesabı bağlantınızı kullanır',
  wb_user_account_modal_error:
    'Bağlantılı bir hesap bulunamadı. Lütfen tekrar deneyin.',
  wb_user_account_modal_footer_privacy: 'Gizlilik Politikası',
  wb_user_account_modal_footer_terms: 'Kullanım Koşulları',
  wb_user_account_modal_footer_text:
    'Giriş yaptıktan sonra Xsolla tarafından yönetilen bir satın alma sitesine yönlendirileceksiniz. Sitede yer alan içeriklerden ve satın alımlarınızla ilgili her türlü detaydan Xsolla sorumludur.',
  wb_user_account_modal_kingdom: 'Krallık',
  wb_user_account_modal_logout: 'Çıkış Yap',
  wb_user_account_modal_title: 'BU GOTC HESABINI BAĞLAYIN',
};

export default localizations;
