import {
  Subscription,
  SubscriptionPeriodType,
} from '@site-builder/common/src/types/model/subscription';

import type { SelectElement } from './types';
import type { FiveSymbolLocale } from '@site-builder/common/src/types/locale';
import type { TwoSymbolLocale } from '@site-builder/common/src/utils/locale';

import {
  getCurrencyDisplay,
  periodMultiple,
  periodSingle,
} from './currency-formatter';
import {
  getLifetimeSubscriptionButtonText,
  getCommonSubscriptionButtonText,
} from './translation';

type Props = {
  subscriptions: Subscription[];
  locale: TwoSymbolLocale;
  needPrefix?: boolean;
  needTitles?: boolean;
};

type PeriodNameProps = {
  periodValue: number;
  subscriptionType: SubscriptionPeriodType;
  locale: FiveSymbolLocale;
};

const LIFETIME_SUBSCRIBE_PLAN = 'lifetime';

const getSubscriptionPeriodName = ({
  periodValue,
  subscriptionType,
  locale,
}: PeriodNameProps): string | undefined => {
  if (periodValue !== 1) {
    return periodMultiple[subscriptionType]?.[locale];
  }

  return periodSingle[subscriptionType]?.[locale];
};

const isLifetimeSubscription = (
  subscriptionType: SubscriptionPeriodType
): boolean => subscriptionType === LIFETIME_SUBSCRIBE_PLAN;

const generateSubscriptionButtonText = (
  subscription: Subscription,
  locale: FiveSymbolLocale = 'en-US'
): string => {
  if (!subscription) {
    return '';
  }

  const {
    charge: { amount, currency, period },
  } = subscription;
  const { value: periodValue, type: subscriptionType } = period;

  const fullCurrency = getCurrencyDisplay(currency, amount);

  if (isLifetimeSubscription(subscriptionType)) {
    return getLifetimeSubscriptionButtonText(locale, { fullCurrency });
  }

  const periodCount = periodValue !== 1 ? periodValue : '';
  const periodName = getSubscriptionPeriodName({
    periodValue,
    subscriptionType,
    locale,
  });

  return getCommonSubscriptionButtonText(locale, {
    fullCurrency,
    periodCount,
    periodName,
  });
};

const renderSubscriptions = ({
  subscriptions,
  locale,
  needPrefix = true,
  needTitles = true,
}: Props): SelectElement[] => {
  let lastCurrency = '';
  return subscriptions
    .sort((a: Subscription, b: Subscription) =>
      a.charge.currency > b.charge.currency ? 1 : -1
    )
    .reduce(
      (
        accumulator,
        { external_id: externalId, name, charge }: Subscription
      ) => {
        if (charge && charge.currency !== lastCurrency && needTitles) {
          accumulator.push({
            id: 'empty-line',
            name: charge.currency,
            disabled: true,
          });
          lastCurrency = charge.currency;
        }
        accumulator.push({
          id: needPrefix ? `s${externalId}` : externalId,
          name: name[locale] || name.en,
        });
        return accumulator;
      },
      [] as SelectElement[]
    );
};

export {
  renderSubscriptions as default,
  renderSubscriptions,
  generateSubscriptionButtonText,
  getSubscriptionPeriodName,
  isLifetimeSubscription,
  LIFETIME_SUBSCRIBE_PLAN,
};
