import { validateSteamVideoUrl } from '@site-builder/common/src/utils/video';
import Color from 'color';

import type {
  PageThemeType,
  LandingThemeType,
} from '@site-builder/common/src/types/model/landing';

import detectModalBackgroundColor from './colors/detectModalBackgroundColor';
import { getVideoThumbnail } from './video';

export const rgbaToString = (colorObj: {
  r: number;
  g: number;
  b: number;
  a?: number;
}): string => {
  const { r, g, b, a } = colorObj;

  return `rgba(${r}, ${g}, ${b}, ${a || 0})`;
};

export const getBlackOrWhite = (color: string) =>
  Color(color).isLight() ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)';
export const orderItemImageBackgroundColor = (color: string) =>
  Color(color).isLight() ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)';

export const mix = (arr: Array<{ color: string; weight: number }>): string =>
  arr
    .slice(1)
    .reduce(
      (acc, { color, weight }) => acc.mix(Color(color), weight),
      Color(arr[0].color)
    )
    .toString();

export const setAlpha = (color: string, alphaValue: number): string =>
  Color(color).alpha(alphaValue).string();

export const getColorTone = (color: string, tone: number) => {
  const computeHue = (h: number, tone: number) => {
    if (tone === 500) {
      return h;
    }
    return h + ((tone - 500) / 100) * 3;
  };

  const [h, s] = Color(color).hsl().array();
  const hslColor = {
    h: computeHue(h, tone),
    s,
    l: 100 - Math.ceil(tone / 10),
  };
  return Color(hslColor).toString();
};

const getColorWithMinAlpha = (color, minAlpha: number) => {
  // @ts-ignore
  if (Color(color).object().alpha < minAlpha) {
    return setAlpha(color, minAlpha);
  }
  return color;
};

const isTransparent = (color) => {
  return Color(color).object().alpha === 0;
};

const getBorderSizeByOpacity = (color) => {
  if (isTransparent(color)) {
    return 0;
  }
  return '1px';
};

export const getColorScheme = ({
  pageTheme,
  landingTheme,
}: {
  pageTheme: PageThemeType;
  landingTheme: LandingThemeType;
}) => {
  const {
    textColor,
    accentColor,
    buttonBorderRadius,
    backgroundBlur,
    buttonTextColor,
    headerFont,
    mainFont,
    secondaryColor,
    overlayColor,
    borderColor,
  } = landingTheme;
  const { pictureBackground, videoBackground } = pageTheme;
  const { color, img, size } = pictureBackground;

  // Steam thumbnail has low quality, use background picture instead
  const bgImage: string =
    videoBackground.enable && !validateSteamVideoUrl(videoBackground.video)
      ? getVideoThumbnail(videoBackground.video)
      : img;
  const background = mix([
    { color: getBlackOrWhite(textColor), weight: 1 },
    { color: accentColor, weight: 0.25 },
  ]);
  const newsPreviewBackgroundColor = Color(textColor).isLight()
    ? 'rgba(0, 0, 0, .3)'
    : 'rgba(255, 255, 255, .3)';
  const modalBackgroundColor = detectModalBackgroundColor(
    pictureBackground.color,
    textColor
  );
  const orderItemImageBackgroundStringColor =
    orderItemImageBackgroundColor(modalBackgroundColor);

  const alphaValues = [0.1, 0.15, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7];
  const alphaColors = alphaValues.reduce((accumulator, currentValue) => {
    const alpha = currentValue.toString().split('.').join('');
    const textColorType = `--text-color-alpha-${alpha}`;
    const accentColorType = `--accent-color-alpha-${alpha}`;
    accumulator[textColorType] = setAlpha(textColor, currentValue);
    accumulator[accentColorType] = setAlpha(accentColor, currentValue);

    return accumulator;
  }, {});

  const commonColors = {
    '--accent-color': accentColor,
    '--accent-color-black-or-white': getBlackOrWhite(accentColor),
    '--border-color': borderColor,
    '--border-color-min-alpha03': getColorWithMinAlpha(borderColor, 0.3),
    '--border-radius': `${buttonBorderRadius / 20}em`,
    '--border-radius-half': `${buttonBorderRadius / 40}em`,
    '--border-radius-light': `${buttonBorderRadius === 0 ? 0 : 4}px`,
    '--border-radius-8': `${Math.min(buttonBorderRadius, 8)}px`,
    '--border-radius-px': `${buttonBorderRadius}px`,
    '--border-size': getBorderSizeByOpacity(borderColor),
    '--background-blur': `${backgroundBlur}px`,
    '--background-color': color,
    '--background-color-alpha1': setAlpha(color, 1),
    '--background-image-size': videoBackground.enable ? 'cover' : size,
    '--button-text-color': buttonTextColor,
    '--headers-font': headerFont,
    '--main-font': mainFont,
    '--modal-background-color': detectModalBackgroundColor(color, textColor),
    '--order-item-image-background': orderItemImageBackgroundStringColor,
    '--overlay-color': overlayColor,
    '--overlay-color-alpha1': setAlpha(overlayColor, 1),
    '--secondary-color': secondaryColor,
    '--secondary-color-alpha1': setAlpha(secondaryColor, 1),
    '--text-color': textColor,
    '--text-color-black-or-white': getBlackOrWhite(textColor),
    '--cart-soft-border-radius': `${buttonBorderRadius / 60}em`,
  };

  const specificColors = {
    color: textColor,
    '--background-image': `url(${bgImage})`,
    '--gdpr-background': background,
    '--locale-selector-text-color': getBlackOrWhite(color),
    '--news-preview-background-color': newsPreviewBackgroundColor,
    '--news-card-background-color-hover': setAlpha(textColor, 0.05),
  };

  const colorTones = {
    '--accent-color-200': getColorTone(secondaryColor, 200),
    '--secondary-color-300': getColorTone(secondaryColor, 300),
    '--background-color-300': getColorTone(setAlpha(color, 1), 300),
    '--background-color-700': getColorTone(setAlpha(color, 1), 700),
    '--background-color-800': getColorTone(setAlpha(color, 1), 800),

    '--secondary-color-50': getColorTone(setAlpha(secondaryColor, 1), 50),
    '--secondary-color-200': getColorTone(setAlpha(secondaryColor, 1), 200),

    '--overlay-color-500-alpha-02': setAlpha(
      getColorTone(overlayColor, 500),
      0.2
    ),
    '--secondary-color-500-alpha-02': setAlpha(
      getColorTone(secondaryColor, 500),
      0.2
    ),
    '--overlay-color-500-max-alpha-02': setAlpha(
      getColorTone(overlayColor, 500),
      isTransparent(overlayColor) ? 0 : 0.2
    ),
    '--secondary-color-500-max-alpha-02': setAlpha(
      getColorTone(secondaryColor, 500),
      isTransparent(secondaryColor) ? 0 : 0.2
    ),
  };

  return {
    ...commonColors,
    ...alphaColors,
    ...specificColors,
    ...colorTones,
  };
};
