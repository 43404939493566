// @flow
export type BlockTypeEnum = {|
  DESCRIPTION: 'description',
  FAQ: 'faq',
  FOOTER: 'footer',
  GALLERY: 'gallery',
  HEADER: 'header',
  HERO: 'hero',
  HTML: 'html',
  NEWS: 'news',
  PACKS: 'packs',
  SUBSCRIPTIONS: 'subscriptions-packs',
  PROMO_SLIDER: 'promoSlider',
  REQUIREMENTS: 'requirements',
  RETAILERS: 'retailers',
  SOCIAL_EMBED: 'embed',
  STORE: 'store',
|};

export const BlockType: BlockTypeEnum = {
  DESCRIPTION: 'description',
  FAQ: 'faq',
  FOOTER: 'footer',
  GALLERY: 'gallery',
  HEADER: 'header',
  HERO: 'hero',
  HTML: 'html',
  NEWS: 'news',
  PACKS: 'packs',
  SUBSCRIPTIONS: 'subscriptions-packs',
  PROMO_SLIDER: 'promoSlider',
  REQUIREMENTS: 'requirements',
  RETAILERS: 'retailers',
  SOCIAL_EMBED: 'embed',
  STORE: 'store',
};
