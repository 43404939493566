/** @prettier @flow */
import { FiveSymbolLocale } from '@site-builder/common/src/types/locale';
import { INTERNAL_CONSTRUCTOR_PATHS } from '@site-builder/common/src/url';
import Cookies from 'js-cookie';
import Toastify from 'toastify-js';

import type { Environment, LocalizedNewsArticle } from '../../utils/types';
import type { UserDataPayload } from '../blocks/user-account-services/types';
import type { RenderMode } from '@site-builder/common/src/types/build';

import {
  LOGIN_API,
  BASE_URL,
  SANDBOX_SECURE_API,
  SECURE_API,
} from '../../config';
import { getSitebuilderURL } from '../../utils/url-helper';
import { COUNTRY_CODE, PARTNER_LOGIN_TOKEN, USER_LOGIN_TOKEN } from './cookies';
import { isSandboxMode } from './sandbox';

export type ProjectIdParam = {
  projectId: string,
};

export type UserTokenParam = {
  userToken: string,
};

export type LandingIdParam = {
  landingId: string,
};

export type CartIdParam = {
  cartId: string,
};

export type LocaleParam = {
  locale: FiveSymbolLocale,
};

export type StoreError = {|
  errorCode: number,
  errorMessage: string,
|};
export type Rewards = {|
  bonus: any[],
  discount: {
    percent: null | string,
  },
  discounted_items: any[],
  is_selectable: boolean,
|};

export const DEFAULT_VC_IMAGE =
  'https://cdn.xsolla.net/img/misc/images/91d3aecf770347428c8c6abdc8a260b8.png';

export const setCookie = (cname: string, cvalue: string) => {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = (name: string) => {
  if (typeof window === 'undefined') {
    // TODO handle case with SSR
    return undefined;
  }
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const countryCode = getCookie(COUNTRY_CODE);
export const getCountryCode = () => countryCode;

export const deleteCookie = (name: string) => {
  Cookies.remove(name);
};

const base64DecodeUnicode = (string) =>
  decodeURIComponent(
    Array.prototype.map
      .call(
        atob(string),
        (c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      )
      .join('')
  );

export const parseJwt = (token?: string): ?UserDataPayload => {
  if (!token) {
    return null;
  }
  try {
    return JSON.parse(
      base64DecodeUnicode(
        token.split('.')[1].replace('-', '+').replace('_', '/')
      )
    );
  } catch (error) {
    return null;
  }
};

const classPreventAction = [
  'x-buy-button-widget-simple',
  'x-buy-button-widget-simple-button-block',
  'x-buy-button-widget-simple-payment-button',
  'x-buy-button-widget-simple-payment-button-amount',
  'translate-message',
  'translate-message-amount',
  'formatted-currency',
  'ui-site-calltoaction--subscription',
  'ui-site-calltoaction__text',
  'period',
];

if (typeof window !== 'undefined') {
  [].slice
    .call(document.querySelectorAll('.pack'))
    .filter((packElement) => packElement.querySelector('.pack__hover-effect'))
    .forEach((packElement) => {
      packElement.classList.add('pack_cursor-pointer');
      packElement.addEventListener('click', (e) => {
        const { target } = e;
        let button = packElement.querySelector(
          '.x-buy-button-widget-simple-payment-button-amount'
        );
        if (!button) {
          button = packElement.querySelector('.ui-site-calltoaction');
        }
        const hasNoButtonInEventPath =
          !e.composedPath ||
          e
            .composedPath()
            .every(
              (el) =>
                el.tagName !== 'BUTTON' &&
                (!el.getAttribute || el.getAttribute('role') !== 'button')
            );
        if (
          (target === button || target.tagName !== 'A') &&
          hasNoButtonInEventPath &&
          button != null &&
          !classPreventAction.some((className) =>
            target.classList.contains(className)
          )
        ) {
          button.click();
        }
      });
    });
}

export const getSeparatedPathName = (pathname: string) =>
  pathname.replace(/\/$/, '').split('/').filter(Boolean);

export const getBaseUrl = () => {
  const { origin, pathname } = window.location;
  const paths = getSeparatedPathName(pathname);
  const isInternalPage = INTERNAL_CONSTRUCTOR_PATHS.includes(paths[0]);
  if (isInternalPage) {
    paths.length = 2;
    return `${origin}/${paths.join('/')}`;
  }
  return origin;
};

export const getSanitizedLoginRedirectUrl = () => {
  const { origin, pathname } = window.location;
  const paths = getSeparatedPathName(pathname);
  return `${origin}/${paths.join('/')}`;
};

export const setUserAttribute = (paToken: string, data: Object) => {
  const url = `${LOGIN_API}/attributes/users/me/update`;
  return fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${paToken}`,
    }),
    body: JSON.stringify(data),
  });
};

// Получение статуса по подпискам
export const getSubscriptionsInfo = async ({
  token,
  renderMode,
}: {
  token: string,
  renderMode: RenderMode,
}) => {
  const secureUrl = isSandboxMode(renderMode) ? SANDBOX_SECURE_API : SECURE_API;
  const subscriptionsInfo = await fetch(
    `${secureUrl}/paystation2/api/recurring/active?access_token=${token}`,
    {
      method: 'GET',
    }
  );

  return subscriptionsInfo.json();
};

export const getSubscribersByAttribute = async (body: string) => {
  const url = `${BASE_URL}/customAttributes`;
  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(body),
  });
  return response.json();
};

export const getTokenFromCookie = (isPreviewMode: boolean = false) =>
  (isPreviewMode && getCookie(PARTNER_LOGIN_TOKEN)) ||
  getCookie(USER_LOGIN_TOKEN);

export const showNotification = (
  message: string,
  type: 'error' | 'success'
) => {
  const notification = document.querySelectorAll('.landing-notification');
  if (notification.length > 2) {
    notification.forEach((e) => e.remove());
  }

  Toastify({
    text: message,
    className: `landing-notification landing-notification--${type}`,
    duration: 60000,
    close: true,
    gravity: 'top',
    position: 'center',
    stopOnFocus: true,
  }).showToast();
};

export const getOldLoginWidgetTheme = async (loginid: string) => {
  const response = await fetch(
    `${LOGIN_API}/widget/settings?projectId=${loginid}`,
    {
      method: 'GET',
    }
  );
  const result = await response.json();
  const { error, custom_css: customCss } = result;
  if (error) {
    throw Object(result);
  }
  return customCss;
};

export const getNews = async ({
  launcherId,
  projectId,
  limit = 6,
  locale,
  offset = 0,
  environment,
  isPreviewFrameMode,
}: {
  launcherId: string,
  projectId: string,
  limit?: number,
  locale: FiveSymbolLocale,
  offset?: number,
  environment: Environment,
  isPreviewFrameMode: boolean,
}): Promise<{
  news: LocalizedNewsArticle[],
  count: number,
}> => {
  const url = new URL(
    `${getSitebuilderURL(
      environment
    )}/public/launcher/${launcherId}/project/${projectId}/news`
  );
  url.searchParams.set('limit', String(limit));
  url.searchParams.set('locale', locale);
  url.searchParams.set('offset', String(offset));
  if (isPreviewFrameMode) {
    url.searchParams.set(
      'defaultSub',
      new URLSearchParams(window.location.search).get('adminDefaultSubs') || ''
    );
    url.searchParams.set('isPreview', 'true');
  } else {
    url.searchParams.set(
      'xsolla_login_token_sb',
      getCookie(USER_LOGIN_TOKEN) || ''
    );
  }

  const response = await fetch(url.toString(), {
    method: 'GET',
  });

  return response.json();
};

export const toggleElement = (
  elem: HTMLElement | null,
  predicate: () => boolean
) => {
  if (!elem) {
    throw new Error('Impossible to hide the element non-existent on the page');
  }
  if (predicate()) {
    elem.removeAttribute('hidden');
  } else {
    elem.setAttribute('hidden', 'true');
  }
};
