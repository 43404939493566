import { AirtableRecord } from '@site-builder/common/src/types/model/retailers';

import { requestGetRetailersData } from '../../../utils/api';
import { removeDuplicates } from '../../../utils/array-helper';
import { Action, Dispatch, GetState } from '../../types';
import { RootState } from '../../types/root-state';
import { changeValue, addSeveralComponents } from './blocks';

export const INIT_RETAILERS = 'INIT_RETAILERS';
export const FAIL_INIT_RETAILERS = 'FAIL_INIT_RETAILERS';

export interface RetailersType {
  pack: string;
  edition: string;
  country: string;
  platform: string;
  retailer: string[];
  link: string;
}

export interface RetailersState {
  records: RetailersType[];
  loading: boolean;
}

const initialState: RetailersState = {
  records: [],
  loading: true,
};

export const addRetailersPack =
  (tableName: string) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      landing: { availableComponents },
      retailersData: { records },
      blocks: { currentBlock },
    } = getState();
    const retailersPacks = removeDuplicates(records, 'pack');

    // Добавляем только в случае НЕ совпадения колличества паков
    if (retailersPacks.length !== currentBlock.components.length) {
      const newComponents = retailersPacks.map((pack) => {
        const newComponent = availableComponents.find(
          (component) => component.type === 'retailers'
        );
        return { ...newComponent, enable: true, packId: pack.pack };
      });
      await dispatch(addSeveralComponents(newComponents));

      dispatch(changeValue('tableName', tableName, true));
    }
  };

export const initRetailers =
  (tableName = '', onLoad = true) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks: { blocks },
    } = getState();
    const retailerBlock = blocks.find((block) => block.module === 'retailers');
    if (retailerBlock) {
      const { tableName: table, databaseName } = retailerBlock.values;

      if (!table && !databaseName && onLoad) {
        return [];
      }

      const retailersData: AirtableRecord[] = await requestGetRetailersData(
        tableName || table,
        databaseName
      );

      if (retailersData.length === 0) {
        dispatch({ type: FAIL_INIT_RETAILERS });
        return false;
      }

      const records = retailersData.map((field) => ({ ...field.fields }));
      dispatch({
        type: INIT_RETAILERS,
        payload: records,
      });
    }

    return true;
  };

export const initServerRetailersData = (retailersData: RetailersType[]) => ({
  type: INIT_RETAILERS,
  payload: retailersData,
});

export default function retailerReducer(
  state: RetailersState = initialState,
  action: { payload: RetailersType[] } & Action
) {
  switch (action.type) {
    case FAIL_INIT_RETAILERS:
    case INIT_RETAILERS:
      return {
        ...state,
        records: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
