export type TwoSymbolLocale =
  | 'en'
  | 'ar'
  | 'bg'
  | 'cs'
  | 'de'
  | 'es'
  | 'fr'
  | 'hu'
  | 'he'
  | 'it'
  | 'ja'
  | 'ko'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'ru'
  | 'th'
  | 'tr'
  | 'vi'
  | 'cn'
  | 'tw';

export type TwoSymbolLocalizedObject = { en: string } & {
  [K in TwoSymbolLocale]: string;
};

export type FiveSymbolLocale =
  | 'ar-AE'
  | 'bg-BG'
  | 'cs-CZ'
  | 'de-DE'
  | 'en-US'
  | 'es-ES'
  | 'fr-FR'
  | 'he-IL'
  | 'hu-HU'
  | 'it-IT'
  | 'ja-JP'
  | 'ko-KR'
  | 'pl-PL'
  | 'pt-BR'
  | 'ro-RO'
  | 'ru-RU'
  | 'th-TH'
  | 'tr-TR'
  | 'vi-VN'
  | 'zh-CN'
  | 'zh-TW';

export const fiveSymbolsMap: {
  [K in FiveSymbolLocale]: TwoSymbolLocale;
} = {
  'ar-AE': 'ar',
  'bg-BG': 'bg',
  'cs-CZ': 'cs',
  'de-DE': 'de',
  'en-US': 'en',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'he-IL': 'he',
  'hu-HU': 'hu',
  'it-IT': 'it',
  'ja-JP': 'ja',
  'ko-KR': 'ko',
  'pl-PL': 'pl',
  'pt-BR': 'pt',
  'ro-RO': 'ro',
  'ru-RU': 'ru',
  'th-TH': 'th',
  'tr-TR': 'tr',
  'vi-VN': 'vi',
  'zh-CN': 'cn',
  'zh-TW': 'tw',
};

export const allowedLanguages = [
  'ar-AE',
  'bg-BG',
  'cs-CZ',
  'de-DE',
  'en-US',
  'es-ES',
  'fr-FR',
  'he-IL',
  'hu-HU',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'pl-PL',
  'pt-BR',
  'ro-RO',
  'ru-RU',
  'th-TH',
  'tr-TR',
  'vi-VN',
  'zh-CN',
  'zh-TW',
];
