/** @prettier */
import React from 'react';
import ReactDOM from 'react-dom';

import { AddingToCartNotification } from '../../../experiments/partnersRequests/AddingToCartNotification';
import Authorization from '../../../experiments/partnersRequests/modals/Authorization';
import UserAccount from '../../../experiments/partnersRequests/modals/UserAccount';
import { getCustomTranslation } from '../../../experiments/partnersRequests/utils';
import { CookieNames } from '../../../utils/cookie';
import { isAfterRegistration } from '../../beforeBlocks/urlSearchParams';
import { getCookie, parseJwt } from '../../helpers/common';
import { getStore } from '../../store';

export const BUY_BUTTON_FIRST_CLICK = 'buy-button-first';

export default (data) => {
  const { auth, locale } = data;
  const translations = getCustomTranslation(locale);
  const checkoutButton = document.querySelector(
    '.ui-site-cart-modal__checkout-button'
  );
  const headerUserAccount = document.querySelector('.header-user-account');
  const loginButton = document.querySelector('.login-button');
  const buyButtons = document.querySelectorAll('[data-require-auth="true"]');
  const mountNode = document.getElementById('experiments');
  const userToken = getCookie(CookieNames.USER_LOGIN_TOKEN);
  const searchParams = new URLSearchParams(window.location.search);
  const customErrorDescription = searchParams.has('error_description');

  let showErrorMessage = false;

  const renderNotification = (type: 'add' | 'update' = 'add', img, name?) => {
    ReactDOM.render(
      <AddingToCartNotification
        img={img}
        name={name}
        text={
          type === 'add'
            ? translations.wb_notification_added_to_cart
            : translations.wb_notification_updated_cart
        }
      />,
      mountNode
    );
  };

  const renderAuthorizationModal = () => {
    ReactDOM.render(
      <Authorization
        loginWidgetId={auth.loginId}
        translations={translations}
        mountNode={mountNode}
        showErrorMessage={showErrorMessage}
      />,
      mountNode,
      () => {
        if (showErrorMessage) {
          window.history.replaceState(
            '',
            '',
            window.location.origin + window.location.pathname
          );
        }
      }
    );
  };

  if (sessionStorage.getItem('logOutFromSocialNetwork') === 'true') {
    renderAuthorizationModal();
    sessionStorage.setItem('logOutFromSocialNetwork', '');
  }

  const renderUserAccountModal = (buttonId) => {
    const userData = parseJwt(userToken);

    ReactDOM.render(
      <UserAccount
        userData={userData}
        translations={translations}
        mountNode={mountNode}
        buttonId={buttonId}
      />,
      mountNode
    );
  };

  const renderAuthorizationModalAfterClick = (button) => {
    const source = button.closest('[data-buy-button-item]');
    localStorage.setItem(BUY_BUTTON_FIRST_CLICK, source.id);
    renderAuthorizationModal();
  };

  if (customErrorDescription) {
    showErrorMessage = true;
    renderAuthorizationModal();
  }

  if (loginButton) {
    loginButton.addEventListener('click', () => {
      showErrorMessage = false;
      localStorage.removeItem(BUY_BUTTON_FIRST_CLICK);
      renderAuthorizationModal();
    });
  }

  if (checkoutButton && !userToken) {
    checkoutButton.addEventListener('click', () => renderAuthorizationModal());
  }

  if (headerUserAccount) {
    headerUserAccount.addEventListener('click', () => renderUserAccountModal());
  }

  if (isAfterRegistration()) {
    const buttonId = localStorage.getItem(BUY_BUTTON_FIRST_CLICK);
    renderUserAccountModal(buttonId);
  }

  if (buyButtons.length > 0 && !userToken) {
    buyButtons.forEach((button) => {
      button.addEventListener('click', () => {
        renderAuthorizationModalAfterClick(button);
      });
    });
  }

  const store = getStore();
  const {
    landingServices: { cart },
  } = store.getState();
  let cartItems = cart.items.map(({ name, imageUrl, sku, quantity }) => ({
    name,
    imageUrl,
    sku,
    quantity,
  }));

  cart.addListener((params) => {
    const addNewItemToCart = cartItems.length < cart.items.length;
    const changeItemQuantityFromLanding =
      cartItems.length === cart.items.length && params?.showPopup;
    if (addNewItemToCart) {
      const [addedItem] = cart.items.filter(
        (newItem) => !cartItems.some((oldItem) => oldItem.sku === newItem.sku)
      );
      const { name, imageUrl } = addedItem;
      renderNotification('add', imageUrl, name);
      const notificationAdding = document.querySelector('.exp-notification');
      notificationAdding.classList.toggle('exp-notification__active', true);
      setTimeout(
        () =>
          notificationAdding.classList.toggle(
            'exp-notification__active',
            false
          ),
        2000
      );
    }

    if (changeItemQuantityFromLanding) {
      const [updatedItem] = cart.items.filter(
        (newItem) =>
          !cartItems.some(
            (oldItem) =>
              oldItem.sku === newItem.sku &&
              oldItem.quantity === newItem.quantity
          )
      );
      if (updatedItem) {
        const { imageUrl } = updatedItem;
        renderNotification('update', imageUrl);
        const notificationAdding = document.querySelector('.exp-notification');
        notificationAdding.classList.toggle('exp-notification__active', true);
        setTimeout(
          () =>
            notificationAdding.classList.toggle(
              'exp-notification__active',
              false
            ),
          2000
        );
      }
    }

    cartItems = cart.items.map(({ name, imageUrl, sku, quantity }) => ({
      name,
      imageUrl,
      sku,
      quantity,
    }));
  });
};
