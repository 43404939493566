/** @prettier */
const localizations = {
  wb_auth_modal_account_bind:
    '「ゲーム・オブ・スローンズ：コンクエスト」の 既存のアカウントに連携しますか？',
  wb_auth_modal_account_create:
    '「ゲーム・オブ・スローンズ：コンクエスト」の アカウントを作成しますか？',
  wb_auth_modal_apple: 'Appleでサインイン',
  wb_auth_modal_conjunction_word: '-または-',
  wb_auth_modal_description:
    'このストアはお客様のアカウントを探すため「ゲーム・オブ・スローンズ：コンクエスト」連携を使用します',
  wb_auth_modal_facebook: 'Facebookでサインイン',
  wb_auth_modal_google: 'Googleでサインイン',
  wb_auth_modal_link_bind:
    'https://hbogamessupport.wbgames.com/hc/ja/articles/360001088488-%E3%82%AF%E3%83%A9%E3%82%A6%E3%83%89%E4%BF%9D%E5%AD%98%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F',
  wb_auth_modal_title:
    '「ゲーム・オブ・スローンズ：コンクエスト」連携でログイン',
  wb_notification_added_to_cart: 'カードに追加済み',
  wb_notification_updated_cart: 'カートが更新されました',
  wb_selector_amount_in_cart: 'カート内',
  wb_user_account_modal_continue: '続ける',
  wb_user_account_modal_description:
    'このストアはお客様のアカウントを探すため「ゲーム・オブ・スローンズ：コンクエスト」連携を使用します',
  wb_user_account_modal_error:
    '連携されているアカウントが見つかりません。もう一度お試しください。',
  wb_user_account_modal_footer_privacy: 'プライバシーポリシー',
  wb_user_account_modal_footer_terms: '利用規約',
  wb_user_account_modal_footer_text:
    'ログイン後は、Xsollaが運営する購入サイトへ移動します。サイトの内容およびお客様の購入に関するすべての責任は、Xsollaが負うものとします。',
  wb_user_account_modal_kingdom: '王国',
  wb_user_account_modal_logout: 'ログアウト',
  wb_user_account_modal_title:
    'この「ゲーム・オブ・スローンズ：コンクエスト」アカウントをリンク',
};

export default localizations;
