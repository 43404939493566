/** @prettier */
const localizations = {
  wb_auth_modal_account_bind: '기존의 계정과 바인드하시겠습니까?',
  wb_auth_modal_account_create: 'GoT:C 계정을 생성하시겠습니까?',
  wb_auth_modal_apple: 'Apple로 로그인',
  wb_auth_modal_conjunction_word: '-또는-',
  wb_auth_modal_description:
    '이 상점은 귀하의 계정을 찾기 위해 왕좌의 게임: 컨퀘스트 바인딩을 사용합니다',
  wb_auth_modal_facebook: 'Facebook으로 로그인',
  wb_auth_modal_google: 'Google로 로그인',
  wb_auth_modal_link_bind:
    'https://hbogamessupport.wbgames.com/hc/en-us/articles/360001088488-Cloud-Saving-FAQ',
  wb_auth_modal_title: 'GOT:C Binding으로 로그인',
  wb_notification_added_to_cart: '카트에 추가하였습니다',
  wb_notification_updated_cart: '카트가 업데이트되었습니다',
  wb_selector_amount_in_cart: '카트에 추가한 수',
  wb_user_account_modal_continue: '계속',
  wb_user_account_modal_description:
    '이 상점은 귀하의 계정을 찾기 위해 왕좌의 게임: 컨퀘스트 바인딩을 사용합니다',
  wb_user_account_modal_error:
    '이 바인딩과 연동된 계정을 찾지 못했습니다. 다시 시도해 주세요.',
  wb_user_account_modal_footer_privacy: '개인정보 처리방침',
  wb_user_account_modal_footer_terms: '이용 약관',
  wb_user_account_modal_footer_text:
    '로그인 후, 사이트 콘텐츠와 구매 관련에 모든 책임을 지는 Xsolla가 운영하는 구매 사이트로 이동될 것입니다.',
  wb_user_account_modal_kingdom: '왕국',
  wb_user_account_modal_logout: '로그아웃',
  wb_user_account_modal_title: '이 GOTC 계정과 연동',
};

export default localizations;
