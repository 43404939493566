/** @prettier */
/* eslint-disable camelcase */
import {
  GK_TYPE,
  BUNDLE_TYPE,
  ERROR_VC_GROUP,
} from '@site-builder/common/src/utils/store';

import type {
  CartItemPrice,
  CartItemObjectExtends,
  StoreAdminGameType,
  AnyStoreItemType,
} from './types';
import type {
  IStoreDRM,
  WarningCartItem,
  StoreItemsGroup,
  IStoreItemPrice,
  StoreItem,
  IStoreVirtualPrice,
  StoreBundle,
} from '@site-builder/common/src/types/store';

import {
  isCustomAuth,
  isInitialVcPacksSorting,
} from '../experiments/partnersRequests/utils';
import { SelectorListItem } from '../types/store/selector-list-item';
import { getCurrencyDisplay } from './currency-formatter';

const ALL_VC_TYPE_NAME = '__all__';
const VC_TYPE = 'vc';
const VI_TYPE = 'vi';
const CUSTOM_AMOUNT = 'custom_amount';
const ERROR_CODE_INVENTORY = 1422;
const DRM_TYPE = 'game_key';

const sortVCPacks = (packs: StoreBundle[]): StoreBundle[] =>
  isCustomAuth() || isInitialVcPacksSorting()
    ? packs
    : packs.sort((pack1: StoreBundle, pack2: StoreBundle): number => {
        const packName1 = pack1.content[0].sku.toUpperCase();
        const packName2 = pack2.content[0].sku.toUpperCase();
        const packQuantity1 = pack1.content[0].quantity;
        const packQuantity2 = pack2.content[0].quantity;

        if (packName1 < packName2) {
          return -1;
        }
        if (packName1 > packName2) {
          return 1;
        }

        if (packQuantity1 < packQuantity2) {
          return -1;
        }
        if (packQuantity1 > packQuantity2) {
          return 1;
        }

        return 0;
      });

const checkForError = ({
  storeItemsGroup,
  storeItemsType,
}: {
  storeItemsGroup: string;
  storeItemsType: string;
}) =>
  (storeItemsGroup === '' &&
    ![GK_TYPE, BUNDLE_TYPE].includes(storeItemsType)) ||
  storeItemsType === '' ||
  storeItemsGroup === ERROR_VC_GROUP;

const collectStoreItemsGroups = (groups: StoreItemsGroup[]) => {
  if (!groups || !Array.isArray(groups)) {
    return [];
  }
  return groups.reduce(
    (arrAcc, group) => [
      ...arrAcc,
      group,
      ...collectStoreItemsGroups(group.children),
    ],
    [] as StoreItemsGroup[]
  );
};

const normalizePrice = ({
  amount,
  amount_without_discount,
  currency,
}: IStoreItemPrice): CartItemPrice => ({
  amount: parseFloat(amount) || 0,
  amountWithoutDiscount: parseFloat(amount_without_discount) || 0,
  currency,
});

interface TransformFields extends AnyStoreItemType {
  drm_name: string;
  quantity: number;
  selectedDRM: string;
}

export const transformFieldsFromStoreToCart = ({
  virtual_item_type,
  sku,
  name,
  type,
  price,
  unit_items,
  bundle_type,
  quantity,
  selectedDRM,
  image_url,
  is_bonus,
  drm_name,
  content,
}: TransformFields): CartItemObjectExtends => ({
  sku,
  type,
  quantity,
  name,
  imageUrl: image_url,
  unitItems: unit_items,
  virtualItemType:
    type === BUNDLE_TYPE && !virtual_item_type
      ? 'consumable'
      : virtual_item_type,
  price: price && normalizePrice(price),
  selectedDRM,
  bundleType: bundle_type,
  isBonus: is_bonus,
  drmName: drm_name || '',
  content: content || [],
});

const filterWarnings = (
  warningsFromServer: WarningCartItem[]
): WarningCartItem[] =>
  warningsFromServer.filter((item) => item.errorCode === ERROR_CODE_INVENTORY);

const getMinGamePrice = (gameDRMItems: IStoreDRM[]) => {
  if (!Array.isArray(gameDRMItems) || gameDRMItems.length === 0) {
    return false;
  }
  return gameDRMItems
    .map((game) => game.price)
    .sort((aPrice, bPrice) => {
      const aPriceValue = Number(aPrice.amount);
      const bPriceValue = Number(bPrice.amount);
      return aPriceValue - bPriceValue;
    })[0];
};

const getFromPriceTag = (tagValue: string, price: string) =>
  tagValue.replace('{price}', price);

const isShowGame = (drm: IStoreDRM) => drm.has_keys || drm.is_pre_order;

const generateDrmList = (drmList: IStoreDRM[]) =>
  drmList.filter(isShowGame).map((item: IStoreDRM): SelectorListItem => {
    const { price } = item;
    const realPrice = getCurrencyDisplay(price.currency, price.amount);
    return {
      value: item.sku,
      name: item.drm_name,
      price: realPrice,
    };
  });

const checkAvailableDRM = (item: StoreItem) =>
  item.unit_items?.[0]?.price && !!item.unit_items.find(isShowGame);

const getAnalyticsTag = (id: string | number) => `$pSB$c${id}`;

const filterGamesList = (
  games: StoreItem[] = [],
  adminGames: StoreAdminGameType[] = []
) =>
  games.filter(checkAvailableDRM).map((game) => {
    const formattedGame = { ...game };
    const filteredItems = formattedGame.unit_items.filter(isShowGame);
    formattedGame.unit_items = [...filteredItems];
    const adminGame = adminGames.find((g) => g.sku === formattedGame.sku);
    if (adminGame) {
      formattedGame.item_id = adminGame.item_id;
    }
    return formattedGame;
  });

const localeStorageUserId = (cartId: string) => `sb-store-uuid-${cartId}`;
const localeStoragePromocode = (cartId: string) =>
  `sb-store-uuid-${cartId}-promocode`;

const countDiscountValue = (
  price: IStoreItemPrice | IStoreVirtualPrice | null
) =>
  price
    ? -Math.round(
        ((Number(price?.amount_without_discount) - Number(price?.amount)) /
          Number(price?.amount_without_discount)) *
          100
      )
    : '';

export {
  collectStoreItemsGroups as default,
  collectStoreItemsGroups,
  ALL_VC_TYPE_NAME,
  VC_TYPE,
  VI_TYPE,
  GK_TYPE,
  BUNDLE_TYPE,
  CUSTOM_AMOUNT,
  DRM_TYPE,
  ERROR_VC_GROUP,
  checkForError,
  normalizePrice,
  sortVCPacks,
  filterWarnings,
  getMinGamePrice,
  getFromPriceTag,
  generateDrmList,
  getAnalyticsTag,
  checkAvailableDRM,
  isShowGame,
  filterGamesList,
  localeStorageUserId,
  countDiscountValue,
  localeStoragePromocode,
};
