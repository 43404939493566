/** @prettier */
const localizations = {
  wb_auth_modal_account_bind: 'Besoin de lier un compte existant ?',
  wb_auth_modal_account_create: 'Besoin de créer un compte GoTC ?',
  wb_auth_modal_apple: 'Connexion avec un compte Apple',
  wb_auth_modal_conjunction_word: '-ou-',
  wb_auth_modal_description:
    'Cette boutique utilise votre lien Game of Thrones: Conquest pour identifier votre compte',
  wb_auth_modal_facebook: 'Connexion avec un compte Facebook',
  wb_auth_modal_google: 'Connexion avec un compte Google',
  wb_auth_modal_link_bind:
    'https://hbogamessupport.wbgames.com/hc/fr-fr/articles/360001088488-FAQ-d-enregistrement-sur-le-Cloud',
  wb_auth_modal_title: 'Connexion avec votre lien GoTC',
  wb_notification_added_to_cart: 'Ajouté à votre panier',
  wb_notification_updated_cart: 'Votre panier a été mis à jour',
  wb_selector_amount_in_cart: 'Dans le panier',
  wb_user_account_modal_continue: 'Continuer',
  wb_user_account_modal_description:
    'Cette boutique utilise votre lien Game of Thrones: Conquest pour identifier votre compte',
  wb_user_account_modal_error:
    "Nous n'avons pas trouvé de compte associé à ce lien. Veuillez réessayer.",
  wb_user_account_modal_footer_privacy: 'Charte de confidentialité',
  wb_user_account_modal_footer_terms: "Conditions d'utilisation",
  wb_user_account_modal_footer_text:
    "Une fois connecté(e), vous serez dirigé(e) vers un site d'achat exploité  par Xsolla. Cette société est seule responsable du contenu du site et de toutes les opérations liées à vos achats.",
  wb_user_account_modal_kingdom: 'Royaume',
  wb_user_account_modal_logout: 'Se déconnecter',
  wb_user_account_modal_title: 'LIER CE COMPTE GOTC',
};

export default localizations;
