// script from the package sets the module directly into Window object
import '@xsolla/metrika/watch';

import type { ScriptsData } from './types';

import { stimulusLoader } from '../utils/module-loader/loaders';
import { setLabelsToSSRLanding } from '../utils/translation';
import { initGameKeyEventHandler } from './actions/buyGameKeys';
import { initSubscriptionEventHandler } from './actions/buySubscription';
import { initUniversalItemEventHandler } from './actions/buyUniversalItem';
import afterBlocksScripts from './afterBlocks';
import beforeBlocksScripts from './beforeBlocks';
import commonBlocksScripts from './blocks';
import { setupBlocksAPI } from './landingAPI';
import { initBlocksServices } from './services';
import { getStore } from './store';
import { translations } from './translations';

const blocksScripts = async (data: ScriptsData, translations) => {
  initBlocksServices(data, translations);
  await commonBlocksScripts({ data, translations });
};

const store = getStore();
const { scripts: scriptsState } = store.getState();

/**
 * Block handlers are @deprecated, use initBlock functions instead
 */
setupBlocksAPI(window);
initGameKeyEventHandler();
initSubscriptionEventHandler();
initUniversalItemEventHandler();

window.addEventListener('DOMContentLoaded', async () => {
  setLabelsToSSRLanding(translations);
  const { needRedirect } = await beforeBlocksScripts(scriptsState);
  if (needRedirect) {
    return;
  }
  await blocksScripts(scriptsState, translations);
  await afterBlocksScripts(scriptsState, translations);
  const { initStimulusApplication } = await stimulusLoader();
  initStimulusApplication();
});
