/**
 * @flow
 * @prettier
 */

import type { Dispatch, GetState } from '../../../types';
import type { RootState } from '../index';
import type { SubscriptionsBlock } from '@site-builder/common/src/flow-types/blocks';
import type { Product } from '@site-builder/common/src/flow-types/subscriptionsPacks/types';
import type { Landing } from '@site-builder/common/src/types/model/landing';
import type { Subscription } from '@site-builder/common/src/types/model/subscription';

import { getSubscriptionPlans, loadProducts } from '../../../../utils/api';
import { constUpdateBlocks, saveBlockProcess } from '../blocks';
import {
  INIT_BLOCK,
  INIT_BLOCKS,
  NETWORK_ERROR,
  FETCHING_PLANS,
  FETCHED_PLANS,
  FETCHING_PRODUCTS,
  FETCHED_PRODUCTS,
  UPDATE_PRODUCT,
  UPDATE_PLAN,
  productsLimit,
  offset,
} from './constants';

type FetchOptions = {
  merchantId: string,
  projectId: string,
  // Landing type has error
  landingId: string | Object,
};

export const initiatingBlocks = () => ({
  type: INIT_BLOCKS,
});

export const initiatingBlock = (subscriptionsPack: SubscriptionsBlock) => ({
  type: INIT_BLOCK,
  payload: {
    subscriptionsPack,
  },
});

export const networkErr = (error: any) => ({
  type: NETWORK_ERROR,
  payload: {
    error,
  },
});

export const fetchingPlans = () => ({
  type: FETCHING_PLANS,
});

export const fetchedPlans = (plans: Subscription[]) => ({
  type: FETCHED_PLANS,
  payload: {
    plans,
  },
});

export const fetchingProducts = () => ({
  type: FETCHING_PRODUCTS,
});

export const fetchedProducts = (products: Product[]) => ({
  type: FETCHED_PRODUCTS,
  payload: {
    products,
  },
});

export const updateProduct = (id: string, updateObj: Object) => ({
  type: UPDATE_PRODUCT,
  payload: {
    updateObj,
    id,
  },
});

export const updatePlan = () => ({
  type: UPDATE_PLAN,
});

const fetchProducts =
  ({ merchantId, projectId, landingId }: FetchOptions) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchingProducts());

    try {
      const productsResponse = await loadProducts({
        merchantId,
        projectId,
        landingId,
        data: {
          project_id: projectId,
        },
        productsLimit,
        offset,
      });

      if (productsResponse.status !== 200) {
        dispatch(fetchedPlans([]));
        dispatch(networkErr({ message: 'Networking error' }));
      }

      dispatch(fetchedProducts(productsResponse.data));
    } catch (e) {
      dispatch(fetchedPlans([]));
      dispatch(networkErr(e));
    }
  };

const fetchPlans =
  ({ merchantId, projectId, landingId }: FetchOptions) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchingPlans());

    try {
      const plansResponse = await getSubscriptionPlans({
        merchantId,
        projectId,
        landingId,
      });

      if (plansResponse.status !== 200) {
        dispatch(fetchedPlans([]));
        dispatch(networkErr({ message: 'Networking error' }));
      }

      dispatch(fetchedPlans(plansResponse.data));
    } catch (e) {
      dispatch(fetchedPlans([]));
      dispatch(networkErr(e));
    }
  };

export const initSubscriptionsPacksBlock =
  (landing: Landing) => (dispatch: Dispatch, getState: GetState<RootState>) => {
    const state = getState();
    const { merchantId, projectId, _id } = landing;
    const { blocks, subscriptionsPacks } = state;

    if (subscriptionsPacks) {
      const { plans, products } = subscriptionsPacks;
      const subscriptionsPacksIndex = blocks.blocks.findIndex(
        (el) => el.module === 'subscriptions-packs'
      );

      if (subscriptionsPacksIndex >= 0) {
        dispatch(initiatingBlocks());

        const subscriptionsPacks = blocks.blocks.filter(
          (el) => el.module === 'subscriptions-packs'
        );

        if (products.length === 0) {
          dispatch(
            fetchProducts({
              merchantId,
              projectId,
              landingId: _id,
            })
          );
        }

        if (plans.length === 0) {
          dispatch(
            fetchPlans({
              merchantId,
              projectId,
              landingId: _id,
            })
          );
        }

        subscriptionsPacks.forEach((sP) => {
          dispatch(initiatingBlock(sP));
        });
      }
    }
  };

export const addSubscriptionsPacksBlock =
  (subscriptionsPack: SubscriptionsBlock) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const state = getState();
    const {
      subscriptionsPacks: { plans, products },
      landing: {
        landing: { merchantId, projectId, _id },
      },
    } = state;

    dispatch(initiatingBlocks());

    if (products.length === 0) {
      dispatch(
        fetchProducts({
          merchantId,
          projectId,
          landingId: _id,
        })
      );
    }

    if (plans.length === 0) {
      dispatch(
        fetchPlans({
          merchantId,
          projectId,
          landingId: _id,
        })
      );
    }

    dispatch(initiatingBlock(subscriptionsPack));
  };

export const updateSubscriptionsPacksBlock =
  (landing: Landing) => (dispatch: Dispatch, getState: GetState<RootState>) => {
    const state = getState();
    const { merchantId, projectId, _id } = landing;
    const { blocks, subscriptionsPacks } = state;

    // may broke landing.tests "setLandingData(). Should set landing data"
    // due to changing async behavior of this fn
    if (subscriptionsPacks) {
      const subscriptionsPacksIndex = blocks.blocks.findIndex(
        (el) => el.module === 'subscriptions-packs'
      );

      if (subscriptionsPacksIndex >= 0) {
        dispatch(initiatingBlocks());

        const subscriptionsPacks = blocks.blocks.filter(
          (el) => el.module === 'subscriptions-packs'
        );

        dispatch(
          fetchProducts({
            merchantId,
            projectId,
            landingId: _id,
          })
        );

        dispatch(
          fetchPlans({
            merchantId,
            projectId,
            landingId: _id,
          })
        );

        subscriptionsPacks.forEach((sP) => {
          dispatch(initiatingBlock(sP));
        });
      }
    }
  };

export const changeProduct =
  (productId: string | number | null) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks,
      subscriptionsPacks: { products },
    } = getState();
    const currentBlock = { ...blocks.currentBlock };

    const groupIdIndex = products.findIndex((el) => el.id === productId);
    const groupId = groupIdIndex >= 0 ? products[groupIdIndex].group_id : null;

    const updatedComponents = currentBlock.components.reduce(
      (acc, val, index) => {
        acc[index] = val;

        if (val.type === 'product') {
          acc[index].value.productId = productId;
        }

        if (val.type === 'plan') {
          acc[index].value.productId = null;
        }

        return acc;
      },
      []
    );

    const currentBlockCopy = {
      ...currentBlock,
      components: updatedComponents,
      values: {
        ...currentBlock.values,
        ...{
          groupId,
        },
      },
    };

    const updateObj = {
      groupId,
      productId,
    };

    dispatch(constUpdateBlocks(blocks, currentBlockCopy));
    dispatch(saveBlockProcess(currentBlockCopy));
    dispatch(updateProduct(currentBlockCopy._id, updateObj));
  };

export const changePlan =
  (planId: string | number | null, componentIndex: number) =>
  (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { blocks } = getState();
    const currentBlock = { ...blocks.currentBlock };

    const updatedComponents = currentBlock.components.reduce(
      (acc, val, index) => {
        acc[index] = val;

        if (val.type === 'plan' && componentIndex === index) {
          acc[index].value.planId = planId === 'create_sub' ? null : planId;
        }

        return acc;
      },
      []
    );

    const currentBlockCopy = {
      ...currentBlock,
      components: updatedComponents,
    };

    dispatch(constUpdateBlocks(blocks, currentBlockCopy));
    dispatch(saveBlockProcess(currentBlockCopy));
    dispatch(updatePlan());
  };
