// @flow

import type { FiveSymbolLocale } from '@site-builder/common/src/utils/locale';

import { LOGIN_API, LOGIN_DEFAULT_URL, LOGIN_WIDGET_BASE_URL } from '../../../../config';
import { xsollaAnalytics } from '../../../helpers/analytics';
import { getLoginScript } from '../../../helpers/thirdPartyScripts';
import {
  clearLastLoginSource, getConfigMiddleware, getTheme, initLoginButton, LOGIN_WIDGET_ROUTES
} from '../utils';

export type Config = {|
  _generation: 0,
  apiUrl?: string,
  defaultLoginUrl?: string,
  widgetBaseUrl?: string,
  fields?: string[],
  theme?: string,
  locale?: FiveSymbolLocale,
  popupBackgroundColor?: string,
  payload?: string,
  projectId: string,
  callbackUrl?: string,
|}

type EventType = 'load' | 'close' | 'hide popup' | 'registration request' | 'authenticated';

export type LoginInstance = {
  init: (config: Config) => void,
  on: (type: EventType, cb: Function) => void,
  show: () => void,
  eventTypes: {
    LOAD: 'load',
    CLOSE: 'close',
    HIDE_POPUP: 'hide popup',
    REGISTRATION_REQUEST: 'registration request',
    AUTHENTICATED: 'authenticated'
  }
}

export class ZeroGeneration {
  _config: Config;

  _XL: LoginInstance;

  constructor(config: Config) {
    this._config = config;
  }

  // eslint-disable-next-line no-unused-vars
  open(_: $Values<typeof LOGIN_WIDGET_ROUTES>) {
    this._XL.show();
  }

  async init() {
    const { XL } = await getLoginScript();
    window.XL = XL;

    this._XL = XL;
    if (!this._config.projectId) {
      return;
    }
    XL.init(
      getConfigMiddleware()({
        apiUrl: LOGIN_API,
        defaultLoginUrl: LOGIN_DEFAULT_URL,
        widgetBaseUrl: LOGIN_WIDGET_BASE_URL,
        fields: ['email'],
        theme: await getTheme(this._config.projectId),
        popupBackgroundColor: 'rgba(0, 0, 0, .6)',
        ...this._config,
        _generation: 0
      })
    );

    const loginButtons = document.querySelectorAll('.xl_auth');
    loginButtons.forEach(button => initLoginButton({ button }));

    XL.on(XL.eventTypes.CLOSE, () => {
      clearLastLoginSource();
    });

    XL.on(XL.eventTypes.HIDE_POPUP, () => {
      clearLastLoginSource();
    });

    xsollaAnalytics(XA => {
      XL.on(XL.eventTypes.CLOSE, () => {
        XA.elementClick('xsolla_login_close');
      });
      XL.on(XL.eventTypes.HIDE_POPUP, () => {
        XA.elementClick('xsolla_login_hide_popup');
      });
      XL.on(XL.eventTypes.LOAD, () => {
        XA.elementClick('xsolla_login_load');
      });
      XL.on(XL.eventTypes.REGISTRATION_REQUEST, () => {
        XA.elementClick('xsolla_login_registration_request');
      });
    });
  }
}
