/** @prettier */
const localizations = {
  wb_auth_modal_account_bind: 'Нужно привязать существующую учетную запись?',
  wb_auth_modal_account_create: 'Нужно создать учетную запись GoT:C?',
  wb_auth_modal_apple: 'ВОЙТИ ЧЕРЕЗ APPLE',
  wb_auth_modal_conjunction_word: '-или-',
  wb_auth_modal_description:
    'ЭТОТ МАГАЗИН ИСПОЛЬЗУЕТ ПРИВЯЗКУ К GAME OF THRONES: CONQUEST ДЛЯ ПОИСКА ВАШЕЙ УЧЕТНОЙ ЗАПИСИ.',
  wb_auth_modal_facebook: 'ВОЙТИ ЧЕРЕЗ FACEBOOK',
  wb_auth_modal_google: 'ВОЙТИ ЧЕРЕЗ GOOGLE',
  wb_auth_modal_link_bind:
    'https://hbogamessupport.wbgames.com/hc/ru/articles/360001088488-%D0%9E%D1%82%D0%B2%D0%B5%D1%82%D1%8B-%D0%BD%D0%B0-%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D1%8B-%D0%BE-%D1%81%D0%BE%D1%85%D1%80%D0%B0%D0%BD%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B2-%D0%BE%D0%B1%D0%BB%D0%B0%D0%BA%D0%B5',
  wb_auth_modal_title: 'ВОЙТИ С ПРИВЯЗКОЙ ВАШЕЙ УЧЕТНОЙ ЗАПИСИ GOT:C',
  wb_notification_added_to_cart: 'Добавлено в вашу корзину',
  wb_notification_updated_cart: 'Ваша корзина обновлена',
  wb_selector_amount_in_cart: 'В корзину',
  wb_user_account_modal_continue: 'ПРОДОЛЖИТЬ',
  wb_user_account_modal_description:
    'ЭТОТ МАГАЗИН ИСПОЛЬЗУЕТ ПРИВЯЗКУ К GAME OF THRONES: CONQUEST ДЛЯ ПОИСКА ВАШЕЙ УЧЕТНОЙ ЗАПИСИ.',
  wb_user_account_modal_error:
    'Мы не смогли найти учетную запись с такой привязкой. Попробуйте еще раз.',
  wb_user_account_modal_footer_privacy: 'Политика конфиденциальности',
  wb_user_account_modal_footer_terms: 'Пользовательское соглашение',
  wb_user_account_modal_footer_text:
    'После входа в систему вы будете перенаправлены на сайт под управлением компании Xsolla, которая единолично несет ответственность за содержимое сайта и все детали вашей покупки.',
  wb_user_account_modal_kingdom: 'Королевство',
  wb_user_account_modal_logout: 'Выйти',
  wb_user_account_modal_title: 'ПРИВЯЗАТЬ ЭТОТ АККАУНТ GOT:C',
};

export default localizations;
