// @flow
import cn from 'classnames';
import React, { Component } from 'react';

import type { FiveSymbolLocale } from '@site-builder/common/src/utils/locale';

import ReactQuillWrapper from './ReactQuill';
import './style.pcss';

type TextSize = 'small' | 'medium' | 'large' | 'ribbon' | 'h1' | 'h2' | 'h3';
type FieldName = 'title' | 'description' | 'label' | 'question' | 'answer' | 'name' | 'recommended' | 'minimum' | 'ribbon' | 'subtext' | 'title' | 'text' | 'instructionCutTextTag' | 'cardTitle' | 'heading';
type WrapperProps = {|
  size?: TextSize, // если формат не передан, то форматируем исходя из содержимого (h1, h2, h3...)
  field: FieldName,
  style?: Object,
  innerHTML?: Object,
  className?: string,
  dataId: string,
  children?: React$Node
|}

const Wrapper = ({
  style, className, field, size, innerHTML, children, dataId
}: WrapperProps) => (
  <div
    className={cn(
      'ui-site-description',
      `ui-site-description--${field}`,
      { [`ui-site-description--${String(size)}`]: !!size },
      className
    )}
    data-id={dataId}
    dangerouslySetInnerHTML={innerHTML} // eslint-disable-line
    style={style}
  >
    {children}
  </div>
);

type State = {
  text: string,
  locale: FiveSymbolLocale
}


type Props = {
  values: Object,
  locale: FiveSymbolLocale,
  style?: Object,
  onBlur: (value: string) => mixed,
  onCheckoutBlock: () => void,
  field: FieldName,
  size?: TextSize,
  isServerSideRender: boolean,
  notLocale?: boolean,
  className?: string,
  dataId?: string,
  disableTextAlignSettings?: boolean
};

export class EditorText extends Component<Props, State> {
  static defaultProps = {
    style: {},
    notLocale: false
  };

  constructor(props: Props) {
    super(props);
    const {
      values, field, locale, notLocale
    } = props;

    let initialText = '';
    if (notLocale) {
      initialText = values[field];
    } else {
      initialText = values[field][locale];
    }
    this.state = {
      text: initialText,
      locale
    };
  }

  // TODO Неочевидное решение связанное со сменой языка и перерендером, отрефакторить
  static getDerivedStateFromProps(nextProps: Props, prevState: State): State | null {
    // $FlowFixMe
    const { text, isServerSideRender, locale } = nextProps;
    if (!isServerSideRender && prevState.locale !== locale) {
      return {
        locale,
        text
      };
    }

    return null;
  }

  saveText = (value: string): void => {
    const {
      onBlur, values, field, locale, notLocale
    } = this.props;

    if (onBlur) {
      if (notLocale) {
        values[field] = value;
      } else {
        values[field][locale] = value;
      }
      onBlur(value);
    }
  };

  render() {
    const {
      style, onCheckoutBlock, field, size, isServerSideRender, className, disableTextAlignSettings, dataId = ''
    } = this.props;

    const { text } = this.state;

    return (
      <Wrapper
        innerHTML={isServerSideRender ? ({ __html: text }) : undefined}
        className={className}
        dataId={dataId}
        field={field}
        size={size}
        style={style}
      >
        {!isServerSideRender ? (
          <ReactQuillWrapper
            initialText={text}
            onBlur={this.saveText}
            onFocus={onCheckoutBlock}
            disableTextAlignSettings={disableTextAlignSettings}
          />
        ) : undefined}
      </Wrapper>
    );
  }
}

